import Estrela from '../components/identidades/Estrela';
import Header from '../components/Header'

function EstrelaPage() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Estrela />
        </div>
    )
}

export default EstrelaPage;