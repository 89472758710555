import React from "react";
import './Servicos.scss'
import bgImage from "../images/bg-servicos.jpg";

function Servicos() {
    return (
        <div id="servicos" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="bg">
                <h2>Serviços</h2>
                <div className="wrap">
                    {/* <div>
                        <img src={image} alt="Macote robótico do time mascote top representando a sessão de serviços" />
                    </div> */}
                    <div className="text">
                        <p>&bull; <b>Consultoria de marca:</b> Oferecemos consultoria especializada e trabalhamos com você para entender seus objetivos de negócio, seu público-alvo e a melhor estratégia para alcançá-los.</p>
                        <br />
                        <p>&bull; <b>Identidade visual:</b> Criamos uma identidade visual consistente, desde o logotipo até os materiais para impressos e digitais, que transmitam os valores e a essência da sua empresa.</p>
                        <br />
                        <p>&bull; <b>Artes Digitais:</b> Criamos todos os tipos de artes para as mídias digitais. Como banners, ícones, artes para redes sociais (capa de facebook, capa de e-book, posts, reels, motion e etc..). </p>
                        <br />
                        <p>&bull; <b>Web Design:</b> Criação de todos os tipos de artes para sites e aplicativos. Como banners, logos, background,  e-mail marketing, blogs e etc..</p>
                        <br />
                        <p>&bull; <b>Criação de conteúdo escrito:</b> Textos persuasivos, copyright, copy para venda de produtos, criação de nome e slogan para empresas.</p>
                        <br />
                        <p>&bull; <b>Papelaria:</b> Criamos todos os tipos de artigos para papelaria. Como cartão de visita, papel timbrado, envelope, convites informativos, adesivos, cardápios, pasta, banners, cartazes, panfletos, folders e etc... </p>
                        <br />
                        <p>&bull; <b>Criação e modernização de Logos:</b> Um logo bem projetado transmite confiança e profissionalismo. É a sua oportunidade de causar uma impressão sólida e positiva nos seus clientes em potencial.</p>
                        <br />
                        <p>&bull; <b>Rebranding:</b> Se sua empresa está passando por um processo de rebranding, nossa agência pode ajudar a repensar e redesenhar a nova direção da marca.</p>
                        <br />
                        <p>&bull; <b>Design de mascotes personalizados:</b> Criamos mascotes exclusivos e personalizados para nossos clientes, levando em consideração suas necessidades e preferências específicas.</p>
                        <br />
                        <p>&bull; <b>Ilustração e Modelagem 3D:</b> Utilizando técnicas avançadas de desenho e modelagem 3D, criamos conteúdos tridimensionais realistas e envolventes.</p>
                        <br />
                        <p>&bull; <b>Ilustração 2D:</b> Nossa equipe de designers e artistas talentosos, desenvolvem ilustrações 2D detalhadas e cativantes.</p>
                        <br />
                        {/* <p>&bull; <b>Animação:</b> Além do design estático, podemos animar os mascotes, adicionando movimento e expressão, o que torna a comunicação da marca mais dinâmica e cativante.</p>
                        <br /> */}
                      
                      
                        
                       {/*  <br />
                        <p>&bull; <b>Serviços de impressão:</b> Além do design, a agência também pode auxiliar na impressão de materiais de marketing, como cartões de visita, flyers, embalagens, entre outros, que apresentam o mascote.</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Servicos;