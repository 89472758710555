import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/oa/desktop/1.jpg'
import img2 from '../../images/identidades/oa/desktop/2.jpg'
import img3 from '../../images/identidades/oa/desktop/3.jpg'
import img4 from '../../images/identidades/oa/desktop/4.jpg'
import img5 from '../../images/identidades/oa/desktop/5.jpg'
import img6 from '../../images/identidades/oa/desktop/6.jpg'
import img7 from '../../images/identidades/oa/desktop/7.jpg'
import img8 from '../../images/identidades/oa/desktop/8.jpg'
import img9 from '../../images/identidades/oa/desktop/9.jpg'
import img10 from '../../images/identidades/oa/desktop/10.jpg'
import img11 from '../../images/identidades/oa/desktop/11.jpg'
/* import img12 from '../../images/identidades/oa/desktop/12.jpg' */
import img13 from '../../images/identidades/oa/desktop/13.jpg'
import img14 from '../../images/identidades/oa/desktop/14.jpg'
import img15 from '../../images/identidades/oa/desktop/15.jpg'
import img16 from '../../images/identidades/oa/desktop/16.jpg'
import img17 from '../../images/identidades/oa/desktop/17.jpg'
import img18 from '../../images/identidades/oa/desktop/18.jpg'
import img19 from '../../images/identidades/oa/desktop/19.jpg'
import img20 from '../../images/identidades/oa/desktop/20.jpg'
import img21 from '../../images/identidades/oa/desktop/21.jpg'
import img22 from '../../images/identidades/oa/desktop/22.jpg'
import img23 from '../../images/identidades/oa/desktop/23.jpg'
import img24 from '../../images/identidades/oa/desktop/24.jpg'
import img25 from '../../images/identidades/oa/desktop/25.jpg'
import img26 from '../../images/identidades/oa/desktop/26.jpg'
import img27 from '../../images/identidades/oa/desktop/27.jpg'
import img28 from '../../images/identidades/oa/desktop/28.jpg'
import img29 from '../../images/identidades/oa/desktop/29.jpg'
import img30 from '../../images/identidades/oa/desktop/30.jpg'

function OA() {
    return (
        <div className="presentation">
            <img src={img1} alt="OA Identidade Visual" />
            <img src={img2} alt="OA Identidade Visual" />
            <img src={img3} alt="OA Identidade Visual" />
            <img src={img4} alt="OA Identidade Visual" />
            <img src={img5} alt="OA Identidade Visual" />
            <img src={img6} alt="OA Identidade Visual" />
            <img src={img7} alt="OA Identidade Visual" />
            <img src={img8} alt="OA Identidade Visual" />
            <img src={img9} alt="OA Identidade Visual" />
            <img src={img10} alt="OA Identidade Visual" />
            <img src={img11} alt="OA Identidade Visual" />
         {/*    <img src={img12} alt="OA Identidade Visual" /> */}
            <img src={img13} alt="OA Identidade Visual" />
            <img src={img14} alt="OA Identidade Visual" />
            <img src={img15} alt="OA Identidade Visual" />
            <img src={img16} alt="OA Identidade Visual" />
            <img src={img17} alt="OA Identidade Visual" />
            <img src={img18} alt="OA Identidade Visual" />
            <img src={img19} alt="OA Identidade Visual" />
            <img src={img20} alt="OA Identidade Visual" />
            <img src={img21} alt="OA Identidade Visual" />
            <img src={img22} alt="OA Identidade Visual" />
            <img src={img23} alt="OA Identidade Visual" />
            <img src={img24} alt="OA Identidade Visual" />
            <img src={img25} alt="OA Identidade Visual" />
            <img src={img26} alt="OA Identidade Visual" />
            <img src={img27} alt="OA Identidade Visual" />
            <img src={img28} alt="OA Identidade Visual" />
            <img src={img29} alt="OA Identidade Visual" />
            <img src={img30} alt="OA Identidade Visual" />
        </div>
    );
}

export default OA;