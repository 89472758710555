import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/selaria/desktop/1.jpg'
import img2 from '../../images/identidades/selaria/desktop/2.jpg'
import img3 from '../../images/identidades/selaria/desktop/3.jpg'
import img4 from '../../images/identidades/selaria/desktop/4.jpg'
import img5 from '../../images/identidades/selaria/desktop/5.jpg'
import img6 from '../../images/identidades/selaria/desktop/6.jpg'
import img7 from '../../images/identidades/selaria/desktop/7.jpg'
import img8 from '../../images/identidades/selaria/desktop/8.jpg'

function Selaria() {
    return (
        <div className="presentation">
            <img src={img1} alt="Selaria identidade visual" />
            <img src={img2} alt="Selaria identidade visual" />
            <img src={img3} alt="Selaria identidade visual" />
            <img src={img4} alt="Selaria identidade visual" />
            <img src={img5} alt="Selaria identidade visual" />
            <img src={img6} alt="Selaria identidade visual" />
            <img src={img7} alt="Selaria identidade visual" />
            <img src={img8} alt="Selaria identidade visual" />
        </div>
    );
}

export default Selaria;