import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner from "../images/banner.jpg?q=1";
import bannerMobile from "../images/banner-mobile.jpg?q=1";
import banner2 from "../images/banner2.jpg?q=3";
import banner2Mobile from "../images/banner2-mobile.jpg?q=3";
import banner3 from "../images/banner3.jpg";
import banner3Mobile from "../images/banner3-mobile.jpg";

import "./Carousel.scss"

function CarouselBootstrap() {
  return (
    <Carousel indicators={false} id="banners">
      <Carousel.Item>
        <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
          <picture>
            <source media="(max-width: 800px)" srcSet={banner2Mobile} alt="Banner Modelagem 3D." />
            <img className="d-block w-100" src={banner2} alt="Banner Modelagem 3D." />
          </picture>
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
          <picture>
            <source media="(max-width: 800px)" srcSet={banner3Mobile} alt="Banner Modelagem 3D." />
            <img className="d-block w-100" src={banner3} alt="Banner Modelagem 3D." />
          </picture>
          {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
          <picture>
            <source media="(max-width: 800px)" srcSet={bannerMobile} alt="Banner promocional 50%." />
            <img className="d-block w-100" src={banner} alt="Banner promocional 50%." />
          </picture>
          {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBootstrap;
