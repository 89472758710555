import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/xavier/desktop/1.jpg'
import img2 from '../../images/identidades/xavier/desktop/2.jpg'
import img3 from '../../images/identidades/xavier/desktop/3.jpg'
import img4 from '../../images/identidades/xavier/desktop/4.jpg'
import img5 from '../../images/identidades/xavier/desktop/5.jpg'
import img6 from '../../images/identidades/xavier/desktop/6.jpg'
import img7 from '../../images/identidades/xavier/desktop/7.jpg'
import img8 from '../../images/identidades/xavier/desktop/8.jpg'
import img9 from '../../images/identidades/xavier/desktop/9.jpg'
import img10 from '../../images/identidades/xavier/desktop/10.jpg'
import img11 from '../../images/identidades/xavier/desktop/11.jpg'
import img12 from '../../images/identidades/xavier/desktop/12.jpg'
import img13 from '../../images/identidades/xavier/desktop/13.jpg'
import img14 from '../../images/identidades/xavier/desktop/14.jpg'
import img15 from '../../images/identidades/xavier/desktop/15.jpg'
import img16 from '../../images/identidades/xavier/desktop/16.jpg'
import img17 from '../../images/identidades/xavier/desktop/17.jpg'
import img18 from '../../images/identidades/xavier/desktop/18.jpg'
import img19 from '../../images/identidades/xavier/desktop/19.jpg'
import img20 from '../../images/identidades/xavier/desktop/20.jpg'
import img21 from '../../images/identidades/xavier/desktop/21.jpg'
import img22 from '../../images/identidades/xavier/desktop/22.jpg'


function Xavier() {
    return (
        <div className="presentation">
            <img src={img1} alt=" Identidade Visual" />
            <img src={img2} alt="Xavier Identidade Visual" />
            <img src={img3} alt="Xavier Identidade Visual" />
            <img src={img4} alt="Xavier Identidade Visual" />
            <img src={img5} alt="Xavier Identidade Visual" />
            <img src={img6} alt="Xavier Identidade Visual" />
            <img src={img7} alt="Xavier Identidade Visual" />
            <img src={img8} alt="Xavier Identidade Visual" />
            <img src={img9} alt="Xavier Identidade Visual" />
            <img src={img10} alt="Xavier Identidade Visual" />
            <img src={img11} alt="Xavier Identidade Visual" />
            <img src={img12} alt="Xavier Identidade Visual" />
            <img src={img13} alt="Xavier Identidade Visual" />
            <img src={img14} alt="Xavier Identidade Visual" />
            <img src={img15} alt="Xavier Identidade Visual" />
            <img src={img16} alt="Xavier Identidade Visual" />
            <img src={img17} alt="Xavier Identidade Visual" />
            <img src={img18} alt="Xavier Identidade Visual" />
            <img src={img19} alt="Xavier Identidade Visual" />
            <img src={img20} alt="Xavier Identidade Visual" />
            <img src={img21} alt="Xavier Identidade Visual" />
            <img src={img22} alt="Xavier Identidade Visual" />
        </div>
    );
}

export default Xavier;