import React from "react";
import './Sobre.scss'
import missao from "../images/missao.png";
import visao from "../images/visao.png";
import valores from "../images/valores.png";
import image1 from "../images/sobre1.jpg";
import image2 from "../images/sobre2.jpg";

function Sobre() {
    return (
        <div id="sobre" style={{ background: `#fff` }}>
            <div className="wrap">
                <div className="block">
                    <img src={image1} alt="Cachorro representando um personagem humano da OA Comunicação" />
                </div>
                <div className="block-text">
                    <h2>Sobre Nós</h2>
                    <p>Em um mundo cada vez mais digital, as empresas precisam se comunicar de forma inovadora para se destacar. Somos uma agência de comunicação que utiliza as mais novas tecnologias para criar soluções criativas e impactantes para nossos clientes.</p>
                    <p>Na OA Comunicação Digital, não apenas criamos designs. Somos um time dedicado de mentes criativas que acredita que uma marca poderosa vai além de um simples logotipo. É a expressão visual de uma narrativa, uma promessa que ressoa com seu público-alvo. Nossa abordagem é centrada no cliente, mergulhando profundamente em compreender a essência da sua marca para criar soluções que se destacam no mercado.</p>
                    <p>Da concepção de logos marcantes ao desenvolvimento completo de identidades visuais, branding estratégico, rebranding revigorante, papelaria elegante, web design intuitivo e até a criação de mascotes carismáticos para suas campanhas publicitárias, oferecemos um portfólio completo de serviços de comunicação e design. Cada projeto é uma oportunidade única de contar uma história visual que ressoa com seu público-alvo e eleva sua marca a novos patamares.</p>
                    <p>Junte-se a nós enquanto transformamos sua visão em realidade.</p>
                </div>
                <div className="block">
                    <img src={image2} alt="Cachorro representando um personagem humano da OA Comunicação" />
                </div>
            </div>
            <br />
            <div className="vision">
                <div className="block">
                    <img src={missao} alt="Missão" />
                    <h3>Missão</h3>
                    <p>Nossa missão é conectar marcas e pessoas, utilizando nossa expertise em comunicação, tecnologia e publicidade para criar trabalhos e campanhas que inspiram, potencializam e transformam seu negócio.</p>
                </div>
                <div className="block">
                    <img src={visao} alt="Visão" />
                    <h3>Visão</h3>
                    <p>Ser uma agência de comunicação, publicidade e propaganda, reconhecida pela excelência na prestação de serviços e pelo impacto positivo que causamos na sociedade.</p>
                </div>
                <div className="block">
                    <img src={valores} alt="Valores " />
                    <h3>Valores</h3>
                    <p>Criatividade</p>
                    <p>Qualidade</p>
                    <p>Compromisso</p>
                    <p>Comunicação</p>
                    <p>Satisfação do Cliente</p>
                    <p>Paixão</p>
                </div>
            </div>
        </div>
    )
}


export default Sobre;