import React from "react";
import './Portifolio.scss'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import logo from '../images/logos/logo-principal.jpg';
import mascote from '../images/portifolio/mascote.png';
import midiassociais from '../images/portifolio/midias-sociais.jpg?q=2';
import consultoria from '../images/portifolio/contultoria.jpg';
import bgImage from "../images/bg-portifolio.jpg";




function Portifolio() {
    return (
        <div id="portifolio">
            <div class="portifolio-servicos" style={{ backgroundImage: `url(${bgImage})` }}>
                <h2>Nosso Portfólio</h2>
                <div className="wrap" >
                    <Card style={{ width: '16.5rem' }}>
                        <Card.Img variant="top" src={logo} />
                        <Card.Body>
                            <Card.Title>Criação de Logos</Card.Title>
                            <Card.Text>
                                Logos modernos e personalizados
                            </Card.Text>
                            <a href="#logos">
                                <Button variant="primary">Acessar</Button>
                            </a>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '16.5rem' }}>
                        <Card.Img variant="top" src={mascote} />
                        <Card.Body>
                            <Card.Title>Criação de Mascotes</Card.Title>
                            <Card.Text>
                                Nosso site com mascotes exclusivos e personalizados.
                            </Card.Text>
                            <a href="https://www.mascotetop.com.br" target="blank">
                                <Button variant="primary">Acessar</Button></a>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '16.5rem' }}>
                        <Card.Img variant="top" src={consultoria} />
                        <Card.Body>
                            <Card.Title>Consultoria de marca</Card.Title>
                            <Card.Text>
                                Oferecemos consultoria especializada.
                            </Card.Text>
                            <a href="#servicos">
                                <Button variant="primary">Acessar</Button></a>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '16.5rem' }}>
                        <Card.Img variant="top" src={midiassociais} />
                        <Card.Body>
                            <Card.Title>Mídias digitais</Card.Title>
                            <Card.Text>
                                Criamos todos os tipos de artes para as mídias digitais.
                            </Card.Text>
                            <a href="https://www.instagram.com/oacomunicacaodigital/" target="blank">
                                <Button variant="primary">Acessar</Button>
                            </a>
                        </Card.Body>
                    </Card>
                </div>
            </div>

        </div>
    );
};

export default Portifolio;