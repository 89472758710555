import "./Identidade.scss"
import { Link } from 'react-router-dom'
import idVisual1 from "../images/id1.jpg"
import idVisual2 from "../images/id2.jpg"
import idVisual3 from "../images/id3.jpg"
import idVisual4 from "../images/id4.jpg"
import searchIcon from "../images/pesquisa.png";



function Identidade() {
    return (
        <div id="identidade">
            <div className="identidade_visual">
                <h2>Comunicação Visual</h2>
                <div className="wrap">
                    <li className="nav-item">
                        <Link className="nav-link" to="/comunicacao-oa">
                            <img src={idVisual1} alt="Capa de identidade Visual cliente OA 1"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/comunicacao-estrela">
                            <img src={idVisual2} alt="Capa de identidade Visual cliente Estrela do Ceu 2"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/comunicacao-xavier">
                            <img src={idVisual3} alt="Capa de identidade Visual cliente Xavier & Melo 3"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/comunicacao-selaria">
                            <img src={idVisual4} alt="Capa de identidade Visual cliente Selaria 4"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                </div>
            </div>
        </div>
    );
}


export default Identidade;

