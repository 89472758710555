//import Identidade from "../components/Identidade";

import { useParams } from 'react-router-dom';
//import { Oaid } from '../components/Oaid';
import OA from '../components/identidades/OA';
import Estrela from '../components/identidades/Estrela';
import Selaria from '../components/identidades/Selaria';
import Xavier from '../components/identidades/Xavier';


function IdentidadeVisual(props) {
    window.scrollTo(0, 0);
    const { id } = useParams()

    return (
        <div>
            {id === 'oa' ? <OA /> : null}
            {id === 'estrela' ? <Estrela /> : null}
            {id === 'xavier' ? <Xavier /> : null}
            {id === 'selaria' ? <Selaria /> : null}

        </div>
    );
}


export default IdentidadeVisual;