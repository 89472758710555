import CarouselBootstrap from "../components/Carousel";
import Servicos from "../components/Servicos";
import Sobre from "../components/Sobre";
import Portifolio from "../components/Portifolio";
import Logos from "../components/Logos";
import Identidade from "../components/Identidade";
import Header from '../components/Header'

function HomePage() {
    return (
        <div>
            <Header></Header>
            <CarouselBootstrap></CarouselBootstrap>
            <Portifolio></Portifolio>
            <Identidade></Identidade>
            <Logos></Logos>
            <Sobre></Sobre>
            <Servicos></Servicos>
        </div>
    )
}

export default HomePage;