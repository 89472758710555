import React from "react";
import './Header.scss'
import mascote from '../images/logo.png?q=2'
import { Link } from 'react-router-dom'



function Header(props) {
    console.log(props.isComunicacao)

    function togleMenu() {
        document.getElementById('menu').classList.contains('show')
            ? document.getElementById('menu').classList.remove('show')
            : document.getElementById('menu').classList.add('show');
    }

    return (
        <header>
            <div className="mobile">
                <a className="logo" href="/" title="link para home">
                    <img src={mascote} alt='Logo Mascote Top' />
                </a>
                <div className="bars" onClick={togleMenu}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
            <nav className="navbar" data-bs-theme="dark" id="menu">
                <div className="close-menu" onClick={togleMenu}></div>
                <div className="bg-mobile" onClick={togleMenu}></div>
                <ul className="nav nav-underline">

                    {props.isComunicacao ? (
                        <li className="nav-item">
                            <Link className="nav-link" to="/" title="link para home" onClick={togleMenu}>
                                Início
                            </Link>
                        </li>
                    ) : null
                    }
                    {props.isComunicacao ? (
                        null
                    ) : <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#portifolio" onClick={togleMenu}>Portfólio</a>
                    </li>}

                    {props.isComunicacao ? (
                        null
                    ) :
                        <li className="nav-item">
                            <a className="nav-link" href="#sobre" onClick={togleMenu}>Sobre</a>
                        </li>}

                    {props.isComunicacao ? (
                        null
                    ) :
                        <li className="nav-item">
                            <a className="nav-link" href="#servicos" onClick={togleMenu}>Serviços</a>
                        </li>
                    }
                    <li className="nav-item">
                        <a className="nav-link" href="#footer" onClick={togleMenu}>Contato</a>
                    </li>
                </ul>
            </nav >
        </header >
    );
}

export default Header;
