import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ImagePort from "./ImagePort";
import "./Logos.scss";


import logo1 from "../images/logos/logo1.jpg";
import logo2 from "../images/logos/logo2.jpg";
import logo3 from "../images/logos/logo3.jpg";
import logo4 from "../images/logos/logo-principal.jpg";
import logo5 from "../images/logos/logo5.jpg";
import logo6 from "../images/logos/logo6.jpg";
import logo7 from "../images/logos/logo7.jpg";
import logo8 from "../images/logos/logo8.jpg";


function Logos() {
    return (
        <div id="logos">
            <h2>Logos</h2>
            <Carousel interval={null}>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={logo1} number={1}></ImagePort>
                            <ImagePort image={logo2} number={2}></ImagePort>
                            <ImagePort image={logo3} number={3}></ImagePort>
                            <ImagePort image={logo4} number={4}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={logo5} number={5} ></ImagePort>
                            <ImagePort image={logo6} number={6}></ImagePort>
                            <ImagePort image={logo7} number={7}></ImagePort>
                            <ImagePort image={logo8} number={8}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Logos;