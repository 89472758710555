import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/estrela/desktop/1.jpg'
import img2 from '../../images/identidades/estrela/desktop/2.jpg'
import img3 from '../../images/identidades/estrela/desktop/3.jpg'
import img4 from '../../images/identidades/estrela/desktop/4.jpg'
import img5 from '../../images/identidades/estrela/desktop/5.jpg'
import img6 from '../../images/identidades/estrela/desktop/6.jpg'
import img7 from '../../images/identidades/estrela/desktop/7.jpg'
import img8 from '../../images/identidades/estrela/desktop/8.jpg'
import img9 from '../../images/identidades/estrela/desktop/9.jpg'
import img10 from '../../images/identidades/estrela/desktop/10.jpg'
import img11 from '../../images/identidades/estrela/desktop/11.jpg'
import img12 from '../../images/identidades/estrela/desktop/12.jpg'
import img13 from '../../images/identidades/estrela/desktop/13.jpg'
import img14 from '../../images/identidades/estrela/desktop/14.jpg'
import img15 from '../../images/identidades/estrela/desktop/15.jpg'
import img16 from '../../images/identidades/estrela/desktop/16.jpg'

function Estrela() {
    return (
        <div className="presentation">
            <img src={img1} alt="Estrela do céu identidade visual" />
            <img src={img2} alt="Estrela do céu identidade visual" />
            <img src={img3} alt="Estrela do céu identidade visual" />
            <img src={img4} alt="Estrela do céu identidade visual" />
            <img src={img5} alt="Estrela do céu identidade visual" />
            <img src={img6} alt="Estrela do céu identidade visual" />
            <img src={img7} alt="Estrela do céu identidade visual" />
            <img src={img8} alt="Estrela do céu identidade visual" />
            <img src={img9} alt="Estrela do céu identidade visual" />
            <img src={img10} alt="Estrela do céu identidade visual" />
            <img src={img11} alt="Estrela do céu identidade visual" />
            <img src={img12} alt="Estrela do céu identidade visual" />
            <img src={img13} alt="Estrela do céu identidade visual" />
            <img src={img14} alt="Estrela do céu identidade visual" />
            <img src={img15} alt="Estrela do céu identidade visual" />
            <img src={img16} alt="Estrela do céu identidade visual" />
        </div>
    );
}

export default Estrela;