import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer';

function RootLayout() {
    return (
        <>
            <Outlet></Outlet>
            <Footer></Footer>
        </>
    );

}

export default RootLayout;