import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/js/src/carousel.js"
import "bootstrap/js/src/util/index.js"
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomePage from './pages/Home';
import OAPage from './pages/OA';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';
import IdentidadeVisual from './pages/Identidade-visual';
import EstrelaPage from './pages/Estrela';
import XavierPage from './pages/Xavier';
import SelariaPage from './pages/Selaria';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage/>,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/comunicacao-oa', element: <OAPage /> },
      { path: '/comunicacao-estrela', element: <EstrelaPage /> },
      { path: '/comunicacao-xavier', element: <XavierPage /> },
      { path: '/comunicacao-selaria', element: <SelariaPage /> },
      { path: '/identidade-visual/:id', element: <IdentidadeVisual /> },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      {/* <Header></Header>
      <CarouselBootstrap></CarouselBootstrap>
      <Portifolio></Portifolio>
      <Logos></Logos>
      <Sobre></Sobre>
      <Servicos></Servicos>
      <Footer></Footer> */}
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
